// @flow
import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/layout';
import Image from '../../components/image';
import GlossaryLink from '../../components/glossary-link';
import type { FrontMatter, ImageType } from '../../utils/types';
import { withFrontMatter } from '../../components';
import CanyonRatingCard from '../../components/canyon-rating-card';
import AllImagesLink from '../../components/all-images-link';

type Props = {
  frontMatter: FrontMatter,
  data: {
    beau_beau_1: ImageType,
    beau_beau_2: ImageType,
    beau_beau_3: ImageType,
    beau_beau_4: ImageType,
    beau_beau_5: ImageType,
    beau_beau_6: ImageType,
    beau_beau_7: ImageType,
    egypt1_1: ImageType,
    egypt1_2: ImageType,
    egypt1_3: ImageType,
    egypt1_4: ImageType,
    egypt1_5: ImageType,
    egypt1_6: ImageType,
    egypt1_7: ImageType,
    egypt1_8: ImageType,
    egypt1_9: ImageType,
    egypt1_10: ImageType,
    egypt1_11: ImageType,
    egypt1_12: ImageType,
    egypt1_13: ImageType,
  },
};

class Blog_2018_03_24_Gla_Rendezvous extends React.Component<Props> {
  render() {
    const { data, frontMatter } = this.props;

    return (
      <Layout frontMatter={frontMatter} toc>
        <p>Fun times practicing skills and meeting new aspirants.</p>
        <h2>Day 1 - Beau Beau</h2>
        <CanyonRatingCard rating="3AII" title="Beau Beau" />
        <p>
          It rained the entire night and through the morning of the first day.
          We in Excursions for a while waiting for the storm to clear while Rick
          showed us how he navigates weather websites and determines what canyon
          to do on a given day.
        </p>
        <p>
          The clouds cleared out and we went through Beau Beau. Surprisingly,
          the canyon was the driest I have ever seen it. There were a few
          scattered pools and most of us made it through the entire canyon
          without getting wet.
        </p>
        <p>
          At the beginning of the canyon we practiced different ways to partner
          capture.
        </p>
        <Image image={data.beau_beau_1} />
        <Image caption="Practicing partner captures" image={data.beau_beau_2} />
        <Image image={data.beau_beau_3} />
        <p>
          The finale of partner captures was stacking people and having the top
          person do a multi-level{' '}
          <GlossaryLink id="human-pyramid">pyramid capture</GlossaryLink>.
        </p>
        <Image caption="Pyramid capture" image={data.beau_beau_4} />
        <p>
          We then practiced <GlossaryLink>chimneying</GlossaryLink> and various
          forms of traversing within the canyon.
        </p>
        <Image
          caption={<GlossaryLink id="down-climb">Down climbing</GlossaryLink>}
          image={data.beau_beau_5}
        />
        <Image
          caption={
            <>
              <GlossaryLink>Stemming</GlossaryLink> or star-bridging
            </>
          }
          image={data.beau_beau_6}
        />
        <Image
          caption={
            <>
              <GlossaryLink>Chimneying</GlossaryLink> or back-bridge
            </>
          }
          image={data.beau_beau_7}
        />
        <h2>Day 2</h2>
        <h3>Beau Beau</h3>
        <p>Not much different from the day before, so only one picture.</p>
        <Image
          caption="While in BB, we came across a little scorpion at the rap"
          image={data.egypt1_1}
        />
        <h3>Egypt 1</h3>
        <CanyonRatingCard rating="3AII" title="Egypt 1" />
        <p>
          We started Egypt 1 by splitting into to two smaller groups. One group
          rappelled in while the other group down climbed the first rappel. The
          down climb was exposed but doable - especially with a{' '}
          <GlossaryLink>top-rope belay</GlossaryLink>.
        </p>
        <Image caption="Rappel 1" image={data.egypt1_2} />
        <Image
          caption="Hiking through the narrows of E1"
          image={data.egypt1_3}
        />
        <Image
          caption="Walls are getting taller in Egypt 1"
          image={data.egypt1_4}
        />
        <p>
          We down climbed many of the rappels using partner captures and
          teamwork instead of ropes which made for a more enjoyable and
          efficient experience.
        </p>
        <Image
          caption="I love the color of the sandstone in the egypts"
          image={data.egypt1_5}
        />
        <Image caption="Posing for a picture" image={data.egypt1_6} />
        <p>
          One rappel we slung the rope through a backpack and used the rope as a
          hand line. As people went down, we added a second backpack for
          additional counter weight. Much quicker than building a deadman
          anchor.
        </p>
        <Image
          caption="Using a packdrag and captures for the last person down"
          image={data.egypt1_7}
        />
        <Image
          caption="Sacrificing dry shoes to haul packs for everybody"
          image={data.egypt1_8}
        />
        <Image caption="Star bridging over water" image={data.egypt1_9} />
        <Image caption="Back bridging over water" image={data.egypt1_10} />
        <p>
          There was one section of water that could be stemmed, several sections
          of mud, and lots of fun down climbs to be had.
        </p>
        <Image
          caption="Egypt 1 drainage and 25 mile wash"
          image={data.egypt1_11}
        />
        <Image image={data.egypt1_12} />
        <Image
          caption="Looking down into the canyon on the way out"
          image={data.egypt1_13}
        />
        <AllImagesLink id={frontMatter.id} />
      </Layout>
    );
  }
}

export default withFrontMatter('2018-03-24-gla-rendezvous')(
  Blog_2018_03_24_Gla_Rendezvous
);

export const query = graphql`
  query {
    beau_beau_1: file(
      relativePath: {
        eq: "images/blog/2018-03-23-beau-beau/2018_03_23_Beau_Beau-1.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    beau_beau_2: file(
      relativePath: {
        eq: "images/blog/2018-03-23-beau-beau/2018_03_23_Beau_Beau-2.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    beau_beau_3: file(
      relativePath: {
        eq: "images/blog/2018-03-23-beau-beau/2018_03_23_Beau_Beau-3.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    beau_beau_4: file(
      relativePath: {
        eq: "images/blog/2018-03-23-beau-beau/2018_03_23_Beau_Beau-4.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    beau_beau_5: file(
      relativePath: {
        eq: "images/blog/2018-03-23-beau-beau/2018_03_23_Beau_Beau-5.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    beau_beau_6: file(
      relativePath: {
        eq: "images/blog/2018-03-23-beau-beau/2018_03_23_Beau_Beau-6.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    beau_beau_7: file(
      relativePath: {
        eq: "images/blog/2018-03-23-beau-beau/2018_03_23_Beau_Beau-7.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    egypt1_1: file(
      relativePath: {
        eq: "images/blog/2018-03-24-egypt-1/2018_03_24_Egypt_1-1.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    egypt1_2: file(
      relativePath: {
        eq: "images/blog/2018-03-24-egypt-1/2018_03_24_Egypt_1-2.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    egypt1_3: file(
      relativePath: {
        eq: "images/blog/2018-03-24-egypt-1/2018_03_24_Egypt_1-3.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    egypt1_4: file(
      relativePath: {
        eq: "images/blog/2018-03-24-egypt-1/2018_03_24_Egypt_1-4.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    egypt1_5: file(
      relativePath: {
        eq: "images/blog/2018-03-24-egypt-1/2018_03_24_Egypt_1-5.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    egypt1_6: file(
      relativePath: {
        eq: "images/blog/2018-03-24-egypt-1/2018_03_24_Egypt_1-6.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    egypt1_7: file(
      relativePath: {
        eq: "images/blog/2018-03-24-egypt-1/2018_03_24_Egypt_1-7.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    egypt1_8: file(
      relativePath: {
        eq: "images/blog/2018-03-24-egypt-1/2018_03_24_Egypt_1-8.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    egypt1_9: file(
      relativePath: {
        eq: "images/blog/2018-03-24-egypt-1/2018_03_24_Egypt_1-9.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    egypt1_10: file(
      relativePath: {
        eq: "images/blog/2018-03-24-egypt-1/2018_03_24_Egypt_1-10.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    egypt1_11: file(
      relativePath: {
        eq: "images/blog/2018-03-24-egypt-1/2018_03_24_Egypt_1-11.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    egypt1_12: file(
      relativePath: {
        eq: "images/blog/2018-03-24-egypt-1/2018_03_24_Egypt_1-12.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    egypt1_13: file(
      relativePath: {
        eq: "images/blog/2018-03-24-egypt-1/2018_03_24_Egypt_1-13.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
